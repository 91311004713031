/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { MdOutlineGppGood } from 'react-icons/md';

const toastify = () =>
  import(/* webpackChunkName: "toastify" */ '@/services/toastify');

function checkPromo(action, state) {
  if (
    action.promotions?.line_items.length !== state.promotions?.line_items.length
  ) {
    action.line_items?.forEach((element) => {
      if (
        !state.line_items.includes(element) &&
        element.promo_description.promotion_name
      ) {
        toastify().then((toast) => {
          toast.success(
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <MdOutlineGppGood
                color="white"
                size={40}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: 'larger',
                    marginBottom: '5px'
                  }}
                >
                  Se agregó artículo promocional
                </span>
                <span>{element.name}</span>
              </div>
            </div>
          );
        });
      }
    });
  }
}

const verifyChangesInCart = (cart, oldCart) => {
  const totalChangesInCart = [];
  if (cart['has_item_without_stock?'] && !oldCart['has_item_without_stock?']) {
    totalChangesInCart.push(
      'Alguno(s) de los artículos en tu carrito no están disponibles. Revísalo para obtener más detalles.'
    );
  }
  cart.line_items.some((item) => {
    if (item['price_was_changed?']) {
      totalChangesInCart.push(
        'Alguno(s) artículos de tu carrito han cambiado de precio, por favor verifica tu carrito para más detalles.'
      );
      return true;
    }
    return false;
  });
  // solo en el checkout
  if (
    cart.line_items.length > oldCart.line_items.length &&
    oldCart.line_items.length !== 0
  ) {
    totalChangesInCart.push(
      'Tenés artículo(s) pendientes en tu carrito de compra, revisa ahora para obtener más información.'
    );
  }
  if (totalChangesInCart.length === 1) {
    return totalChangesInCart[0];
  }
  if (totalChangesInCart.length > 1) {
    return 'Algunos artículos en tu carrito han sido modificados o ya no están disponibles. Por favor, revisa tu carrito para más detalles.';
  }
  return '';
};
const initialState = {
  token: null,
  line_items: [],
  item_count: 0,
  total: 0,
  loading: false,
  errors: null,
  showChangeModal: { visible: false, message: '' },
  showModal: false
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    loadCartInfo(state, { payload }) {
      const changes = verifyChangesInCart(payload, state);
      return {
        ...initialState,
        ...payload,
        showModal: state.showModal,
        showChangeModal: { visible: !!changes, message: changes }
      };
    },
    cartRestore() {
      return initialState;
    },
    addToCart(state, { payload }) {
      checkPromo(payload, state);
      return { ...initialState, ...payload };
    },
    toggleCartModal(state, { payload }) {
      state.showModal = payload;
    },
    hideChangeModalInfo(state) {
      state.showChangeModal = { visible: false, message: '' };
    },
    setCart(_state, action) {
      return { ..._state, ...action.payload };
    }
  }
});

export const {
  loadCartInfo,
  cartRestore,
  addToCart,
  hideChangeModalInfo,
  toggleCartModal,
  setCart
} = cartSlice.actions;
export default cartSlice.reducer;
